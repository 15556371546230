import { Plugins } from '@capacitor/core';
import BackgroundFetch from 'cordova-plugin-background-fetch';
import { waitUntilConnectionIsReady, runBackgroundSync } from './services/backgroundsync';
import router from './services/router';
const { App: CapacitorApp } = Plugins;
CapacitorApp.addListener('appUrlOpen', (data) => {
    const path = data.url.split('.de')[1];
    router.push(path);
});
(async () => {
    const onEvent = async (taskId) => {
        console.info(`[B-Fetch]${new Date().toISOString()} :: recived event ${taskId}`);
        await waitUntilConnectionIsReady();
        await runBackgroundSync();
        BackgroundFetch.finish(taskId, () => {
            console.info('[B-Fetch]${new Date().toISOString()} :: finished');
        }, (error) => {
            console.error('[B-Fetch]${new Date().toISOString()} :: failed', error.message);
        });
    };
    const onTimeout = async (taskId) => {
        console.info(`[B-Fetch]${new Date().toISOString()} :: Timeout: ${taskId}`);
        BackgroundFetch.finish(taskId, () => {
            console.info('[B-Fetch]${new Date().toISOString()} :: finished');
        }, (error) => {
            console.error('[B-Fetch]${new Date().toISOString()} :: failed', error.message);
        });
    };
    const status = await BackgroundFetch.configure({
        minimumFetchInterval: 15,
        requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY,
    }, onEvent, onTimeout);
    console.info(`[B-Fetch]${new Date().toISOString()} :: status: ${status}`);
    console.log('[BSync] run background sync when starting the app');
    await waitUntilConnectionIsReady();
    await runBackgroundSync();
})();
window.addEventListener('online', async () => {
    console.info(`[B-Fetch] client is online, run background Sync`);
    await waitUntilConnectionIsReady();
    await runBackgroundSync();
});
